.overlay {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.image-comment {
  width: 100%;
  height: auto;
}

.butt {
  background-color: #5386E4;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5);
  border: none;
  color: white;
}

.butt:hover {
  background-color: #ef3027;
  box-shadow: 0px 0px 8px 0px white;
  border: none;
  color: white;
}