.spinner {
  position: absolute;
  z-index: 3;
}
.background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.logoload {
  margin: 0 auto;
  padding: 10px;
}

.logoloader {
  max-width: 90%;
  width: 100px;
  animation: rotation 1.8s infinite;
}

@keyframes rotation {
  0% {
    transform: rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
    opacity: 1;
  }
}
