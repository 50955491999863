.ReactModalPortal .ReactModal__Overlay--after-open {
  z-index: 9999;
}

.modal-vtasks {
  width: 100%;
  height: 70%;
  top: 10%;
}

.modal-vtasks-body {
  width: 100%;
  padding: 10px;
  background: white;
  border-radius: 50px;
  border: 1px solid lightgrey;
  z-index: 9999;
}

.modal-backdrop {
  z-index: -1;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
